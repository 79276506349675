import Link from 'next/link'
import { HOTLINE_TELEPHONE } from '@/consts'
import type { GNB_MENUS } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { useCreateBoardModal } from '@/hooks/useCreateBoardModal'
import useVirtuosoSnapshotReset from '@/hooks/useVirtuosoSnapshotReset'

export default function SubMenu({
  subMenu,
  closeModal,
}: {
  subMenu: (typeof GNB_MENUS)[0]['subMenus'][0]
  closeModal: () => void
}) {
  const { text, url, gaSelector, privateUrl = '' } = subMenu
  const { isLoggedIn } = useAuth()
  const { resetScroll } = useVirtuosoSnapshotReset()
  const { handleCreateBoardAuth } = useCreateBoardModal('promotion')
  const linkUrl = privateUrl && isLoggedIn ? privateUrl : url

  if (text === '질문하기') {
    return (
      <button
        data-ga={gaSelector}
        type="button"
        onClick={() => {
          closeModal()
          handleCreateBoardAuth()
        }}
        className="block w-full font-bold text-left"
      >
        {text}
      </button>
    )
  }

  if (text === '의료팀 핫라인') {
    return <a href={`tel:${HOTLINE_TELEPHONE}`}>{text}</a>
  }

  return (
    <Link
      data-ga={gaSelector}
      href={linkUrl}
      className="flex items-center"
      onClick={() => {
        closeModal()
        resetScroll()
      }}
    >
      {text}
    </Link>
  )
}
